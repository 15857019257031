<script lang="ts">
// enable fallthrough attrs
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
import { useImage } from '@vueuse/core'
import { useAttrs } from 'vue'

const attrs = useAttrs()
const { isLoading } = useImage({ src: attrs.src as string | '' })
</script>
<template lang="pug">
div(class="sticky top-0 w-full h-full max-h-screen p-12 lg:p-18 lg:pl-0")
  div(
    v-if="isLoading"
    class="w-full h-full rounded bg-gray-300 bg-gradient-loading animate-loading"
  )
  img(
    v-else
    v-bind="$attrs"
    class="w-full h-full object-cover rounded"
  )
  slot
</template>
